@import 'tailwind.css';
@import 'colors.css';
@import 'fonts.css';
/*@import "datepicker.css";*/

body {
  font-family: PPNeueMontreal;
}

.showArrows::-webkit-inner-spin-button {
  opacity: 1;
}
